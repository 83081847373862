<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("modificaProfilo") }} </v-toolbar-title>
            </v-toolbar>
        

          <v-form>
            <v-container class="py-0">
              <v-row style="margin-top:20px">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="eUser.cUsername"
                    class="purple-input"
                    label="Username"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field 
                    label="Email" 
                    class="purple-input"
                    v-model="eUser.cEmail" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field 
                    label="Nome" 
                    class="purple-input"
                    v-model="eUser.cName" />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field 
                    label="Cognome" 
                    class="purple-input" 
                    v-model="eUser.cSurname"/>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="eUser.xNotes"
                    class="purple-input"
                    label="Note"
                    value=""
                    outlined
                  />
                </v-col>

                <v-col cols="4"  class="text-left" style="margin-bottom: 30px">
                  <v-btn
                    color="darkred"
                    class="mr-0"
                    style="margin-left: 10px"
                    @click="onAggiornaPwdClick"
                  >
                    {{ $t("aggiornaPassword") }}
                  </v-btn>
                </v-col>
                <v-col cols="4" style="margin-bottom: 30px">
                </v-col>
                <v-col cols="4" class="text-right" style="margin-bottom: 30px; ">
                  <v-btn
                    color="darkred"
                    class="mr-0"
                    style="margin-rigth: 5000px"
                    @click="onSalvaProfiloClick"
                  >
                    {{ $t("salva") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-alert
                    id="alertDettaglio"
                    shaped
                    :value="isAlertDetailVisible"
                    :type="alertType"
                    dismissible
                  >
                    {{ messaggioAlert }}
                  </v-alert>
              </v-row>


              <!--
              <v-row>
              </v-row>
                <v-divider class="mx-4"></v-divider>
                <v-card-title
                  class="black--text"
                  style="margin-bottom: 20px; margin-top: 20px"
                >
                  {{ $t("aggiornamentoPassword") }}
                </v-card-title>                
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="passwordVecchia"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Vecchia password"
                    hint="Almeno 8 caratteri"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="passwordNuova"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Nuova password"
                    hint="Almeno 8 caratteri"
                    counter
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="passwordRipetiNuova"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Ripeti nuova password"
                    hint="Almeno 8 caratteri"
                    counter
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="darkred"
                    class="mr-0"
                    rounded
                  >
                    {{ $t("aggiornaPassword") }}
                  </v-btn>
                </v-col>
              </v-row>       
              -->

              <v-dialog v-model="isDialogOpen" max-width="500">
                <CompResetPassword  
                  :user="eUser" 
                  v-on:close-resetpwd="onCloseResetPwd"
                  v-on:new-password="onNewPassword" />
              </v-dialog>
            </v-container>
          </v-form>
        </v-card>
      </v-col>

      <!--
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              CEO / CO-FOUNDER
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              Alec Thompson
            </h4>

            <p class="font-weight-light grey--text">
              Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
            </p>


          </v-card-text>
        </base-material-card>
      </v-col>
      -->
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import CompResetPassword from "@/components/CompResetPassword";

import Utils from "@/utils/Utils";

import UtentiService from "@/service/UtentiService";

@Component({
  components: {
    CompResetPassword,
  },
})
export default class UserProfile extends Vue {

  eUser = {
    id: 0,
    cName: "",
    cSurname: "",
    cUsername: "",
    cEmail: "",
    xNotes: "",
    cPassword: "",
    cOldPassword: "",
  }

  isDialogOpen = false;
  isAlertDetailVisible = false;  
  messaggioAlert = "";
  alertType = "error";


  /*
        show1= false;
        show2= true;
        show3= false;
        
        passwordVecchia = '';
        passwordNuova = '';
        passwordRipetiNuova = '';

        rules= {
          required: value => !!value || 'Obbligatorio.',
          min: v => v.length >= 8 || 'Min 8 caratteri',
          emailMatch: () => (`The email and password you entered don't match`),
        }
*/

  created() {
    // Carico l'oggetto User
    this.eUser.cName = this.$store.state.user.cName;
    this.eUser.cSurname = this.$store.state.user.cSurname;
    this.eUser.cEmail = this.$store.state.user.cEmail;
    this.eUser.cUsername = this.$store.state.user.cUsername;
    this.eUser.xNotes = this.$store.state.user.xNotes;
    this.eUser.cPassword = this.$store.state.user.cPassword;
    this.eUser.cOldPassword = this.$store.state.user.cPassword;
    this.eUser.id = this.$store.state.user.id;
  }

  onAggiornaPwdClick() {
    this.isDialogOpen = true;
  }

  onSalvaProfiloClick() {
    this.saveUserDto();
  }

  onCloseResetPwd() {
    this.isDialogOpen = false;
  }

  onNewPassword(newPassword) {
    //this.eUser.cPassword = newPassword;
    //this.updatePwdDto();

    this.isDialogOpen = false;
    // Aggiorno l'oggetto globale che si trova nello store
    //this.$store.state.user.cPassword = newPassword;

    this.alertType = "success";
    this.messaggioAlert = "Password aggiornata";
    this.showHide_alertDetail(5000);

  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  /// ************************************
  /// DATABASE
  /// ************************************
  saveUserDto() {
    var self = this;

    UtentiService.updateUser(
      self.eUser,
      (resp) => {
        console.log("UpdateUser");
        console.log(resp);

        if (resp.result == 1) {
          // Aggiorno l'oggetto globale che si trova nello store
          self.$store.state.user.cName = self.eUser.cName;
          self.$store.state.user.cSurname = self.eUser.cSurname;
          self.$store.state.user.cEmail = self.eUser.cEmail;
          self.$store.state.user.xNotes = self.eUser.xNotes;

          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }
/*
  updatePwdDto() {
    var self = this;

    UtentiService.updatePassword(
      self.eUser,
      (resp) => {
        console.log("UpdateUser");
        console.log(resp);

        if (resp.result == 1) {
          // Aggiorno l'oggetto globale che si trova nello store
          self.$store.state.user.cPassword = self.eUser.cPassword;
          self.isDialogOpen = false;
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }
  */
}
</script>
