<template>
  <!-- EDIT PASSWORD -->
  <v-card>
    <v-card-title primary-title>{{
      $t("buttons.labels.editPassword")
    }}</v-card-title>
    <v-card-text>
      <v-form ref="formEditPassword">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="formEditPassword.data.passwordOld"
              required
              :rules="rules.password"
              :label="$t('user.labels.passwordOld')"
              :append-icon="
                formEditPassword.showOldPassword ? 'mdi-eye' : 'mdi-eye-off'
              "
              :type="formEditPassword.showOldPassword ? 'text' : 'password'"
              @click:append="
                formEditPassword.showOldPassword =
                  !formEditPassword.showOldPassword
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <span
              class="red--text"
              v-if="!formEditPassword.passwordOldEquals"
              >{{ $t("user.labels.passwordEquals") }}</span
            >
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="formEditPassword.data.passwordNew"
              required
              :rules="rules.passwordNew"
              :label="$t('user.labels.passwordNew')"
              :append-icon="
                formEditPassword.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'
              "
              :type="formEditPassword.showNewPassword ? 'text' : 'password'"
              @click:append="
                formEditPassword.showNewPassword =
                  !formEditPassword.showNewPassword
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :disabled="!formEditPassword.data.passwordNew"
              v-model="formEditPassword.data.passwordNew2"
              required
              :rules="rules.passwordNew"
              :label="$t('user.labels.passwordConfirm')"
              :append-icon="
                formEditPassword.showNewPassword2 ? 'mdi-eye' : 'mdi-eye-off'
              "
              :type="formEditPassword.showNewPassword2 ? 'text' : 'password'"
              @click:append="
                formEditPassword.showNewPassword2 =
                  !formEditPassword.showNewPassword2
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <span
              class="red--text"
              v-if="!formEditPassword.passwordNewEquals"
              >{{ $t("user.labels.passwordEquals") }}</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-alert
              id="alertDettaglio"
              shaped
              :value="isAlertDetailVisible"
              :type="alertType"
              dismissible
            >
              {{ messaggioAlert }}
            </v-alert>
        </v-row>

      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="darkred"
        class="mr-0"
        style="margin-left: 10px"
        @click="onClose"
        >{{ $t("buttons.labels.cancel") }}</v-btn
      >
      <v-btn
        color="darkred"
        class="mr-0"
        style="margin-left: 10px"
        :disabled="saveEnable()"
        @click="onChangePwd"
        >{{ $t("buttons.labels.save") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import Utils from "@/utils/Utils";

import UtentiService from "@/service/UtentiService";

import { Watch } from "vue-property-decorator";

@Component({
  props: {
    user: 0,
  },
})
export default class CompResetPassword extends Vue {
  formEditPassword = {
    showNewPassword: false,
    showNewPassword2: false,
    showOldPassword: false,
    passwordNewEquals: true,
    passwordOldEquals: true,
    data: {
      passwordNew: null,
      passwordNew2: null,
      passwordOld: null,
    },
  };

  rules = {
    password: [
      (v) => !Utils.isNullOrUndefined(v) || this.$t("general.rules.required"),
      (v) =>
        (!Utils.isNullOrUndefined(v) && v.length >= 5) ||
        this.$t("general.rules.lowerChar", { value: 5 }),
      (v) =>
        (!Utils.isNullOrUndefined(v) && v.length <= 50) ||
        this.$t("general.rules.greater", { value: 50 }),
    ],
    passwordNew: [
      (v) => !Utils.isNullOrUndefined(v) || this.$t("general.rules.required"),
      (v) => (v && /\d/.test(v)) || this.$t("general.rules.requiredOneDigit"),
      (v) =>
        (v && /[A-Z]{1}/.test(v)) ||
        this.$t("general.rules.requiredOneCapitalLatter"),
      (v) =>
        (v && /[^A-Za-z0-9]/.test(v)) ||
        this.$t("general.rules.requiredOneSpecialChar"),
      (v) =>
        (!Utils.isNullOrUndefined(v) && v.length >= 5) ||
        this.$t("general.rules.lowerChar", { value: 5 }),
      (v) =>
        (!Utils.isNullOrUndefined(v) && v.length <= 50) ||
        this.$t("general.rules.greater", { value: 50 }),
    ],
  };

  isAlertDetailVisible = false;  
  messaggioAlert = "";
  alertType = "error";

  @Watch("formEditPassword.data.passwordNew")
  onChangePasswordNew() {
    this.checkEqualsPassword();
  }
  @Watch("formEditPassword.data.passwordNew2")
  onChangePasswordNew2() {
    this.checkEqualsPassword();
  }
  /*
  @Watch("formEditPassword.data.passwordOld")
  onChangePasswordOld() {
    this.checkEqualsPasswordOld();
  }
  */
  checkEqualsPassword() {
    if (
      Utils.isNullOrUndefined(this.formEditPassword.data.passwordNew) &&
      Utils.isNullOrUndefined(this.formEditPassword.data.passwordNew2)
    ) {
      this.formEditPassword.passwordNewEquals = true;
    } else {
      this.formEditPassword.passwordNewEquals =
        !Utils.isNullOrUndefined(this.formEditPassword.data.passwordNew) &&
        !Utils.isNullOrUndefined(this.formEditPassword.data.passwordNew2) &&
        this.formEditPassword.data.passwordNew.trim() ===
          this.formEditPassword.data.passwordNew2.trim();
    }
  }

  
  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

/*
checkEqualsPasswordOld() {
    if (
      Utils.isNullOrUndefined(this.formEditPassword.data.passwordOld)
    ) {
      this.formEditPassword.passwordOldEquals = false;
    } else {
      this.formEditPassword.passwordOldEquals = this.formEditPassword.data.passwordOld.trim() === this.old_password.trim();
    }
  }
  */

  onClose() {
    this.$emit("close-resetpwd", true);
    return;
  }

  saveEnable() {
    if (
      Utils.isNotEmpty(this.formEditPassword.data.passwordOld) &&
      Utils.isNotEmpty(this.formEditPassword.data.passwordNew) &&
      Utils.isNotEmpty(this.formEditPassword.data.passwordNew2) &&
      this.formEditPassword.passwordNewEquals && 
      this.formEditPassword.passwordOldEquals 
    ) {
      return !this.$refs.formEditPassword.validate();
    }

    return true;
  }

  onChangePwd() {
    let vm = this;
    if (vm.$refs.formEditPassword.validate() && vm.formEditPassword.passwordNewEquals) {
      vm.user.cPassword = vm.formEditPassword.data.passwordNew;
      vm.user.cOldPassword = this.formEditPassword.data.passwordOld;
      vm.updatePwdDto();

      //this.$emit("new-password", this.formEditPassword.data.passwordNew);
    }
    
  }


  updatePwdDto() {
    var self = this;

    UtentiService.updatePassword(
      self.user,
      (resp) => {
        console.log("UpdateUser");
        console.log(resp);

        if (resp.result == 1) {
          // Aggiorno l'oggetto globale che si trova nello store
          // self.$store.state.user.cPassword = self.eUser.cPassword;
          // Buon fine
          this.$emit("new-password", this.formEditPassword.data.passwordNew);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }

  
}
</script>
